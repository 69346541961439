import './App.css';
import { Routes, Route, HashRouter } from "react-router-dom";
import AuthLayout from './layout/AuthLayout';
import DashboardLayout from './layout/DashboardLayout';

import LoginPage from './pages/auth/LoginPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import RegisterPage from './pages/auth/RegisterPage';

import DashboardPage from './pages/dashboard/DashboardPage';
import TariffPage from './pages/tariff/TariffPage';
import TransactionsPage from './pages/transactions/TransactionsPage';
import CustomersPage from './pages/customers/CutomersPage';
import AssetPage from './pages/asset/AssetPage';
import SettingsPage from './pages/settings/SettingsPage';
import UpdatePasswordPage from './pages/auth/UpdatePasswordPage';
import VerifyEmailPage from './pages/auth/VerifyEmailPage';
import TrackingPage from './pages/tracking';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<DashboardLayout />}>
          <Route index path="/" element={<DashboardPage />} />
          <Route path="/transactions" element={<TransactionsPage />} />
          <Route path="/tariff" element={<TariffPage />} />
          <Route path="/customers" element={<CustomersPage />} />
          <Route path="/asset" element={<AssetPage />} />
          <Route path="/tracking" element={<TrackingPage />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Route>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="register" element={<RegisterPage />} />
          <Route path="verify-email/token/:token/email/:email" element={<VerifyEmailPage />} />
          <Route index path="login" element={<LoginPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="update-password/token/:token/email/:email" element={<UpdatePasswordPage />} />
          {/* <Route path="register" element={<RegisterPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="update-password" element={<RegisterPasswordPage />} /> */}
        </Route>

        <Route path="*" element={<h2>404 Not Found</h2>} />
      </Routes>
    </HashRouter>
  );
}

export default App;
