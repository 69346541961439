import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import PaystackPop from '@paystack/inline-js';

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import SelectInput from "../../components/input/SelectInput";
import CustomInput from "../../components/input/CustomInput";
import CustomModal from "../../components/modal/CustomModal";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';

export default function TransactionsPage() {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [openModal, setOpenModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [currentItem, setCurrentItem] = useState({});

    const [data, setData] = useState([]);
    const [tariffData, setTariffData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/transactions/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 200) {
                    setData(result?.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/tariff/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 200) {
                    setTariffData(result?.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const tableAction = (row) => {
        return (
            <button
                style={{ backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                onClick={() => {
                    setCurrentItem(row);
                    setOpenDetailModal(true);
                }}
            >
                View
            </button>
        );
    };


    const [selectedPayment, setSelectedPayment] = useState('');
    const [selectedPaymentObject, setSelectedPaymentObject] = useState('');
    const [amount, setAmount] = useState(0);

    const handleSelectPayment = (itemId) => {
        setSelectedPayment(itemId);
        const selected = tariffData.filter(item => item.name === itemId);
        const selectedObj = selected[0];
        setSelectedPaymentObject(selectedObj);
    }

    const [customerName, setCustomerName] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [itemQuantity, setItemQuantity] = useState("");

    const handlePay = async () => {
        if (!customerName || !customerEmail || !itemQuantity || !selectedPaymentObject) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                customerName, customerEmail, itemQuantity, itemId: selectedPaymentObject?._id
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/payment/generate-access-code`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result?.code === 200) {
                        const accessCode = result?.data?.data?.access_code;
                        const popup = new PaystackPop();
                        popup.resumeTransaction(accessCode);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openDetailModal} setOpenModal={setOpenDetailModal}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Transaction Details
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenDetailModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Transaction ID</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.transactionId}
                    </div>
                </div>
                <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Customer</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.customerName} ({currentItem?.customerEmail})
                    </div>
                </div>
                <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Item</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.itemName}
                    </div>
                </div>
                <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Quantity</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.itemQuantity}
                    </div>
                </div>
                <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Amount</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.currency} {currentItem?.amount}
                    </div>
                </div>
                <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Payment Date</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.createdAt}
                    </div>
                </div>
                <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Status</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.status}
                    </div>
                </div>
            </CustomModal>
            <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Make Payment
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput
                    label="Customer Name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                />
                <CustomInput
                    label="Customer Email"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                />
                <SelectInput
                    data={tariffData}
                    label="Select Payment"
                    value={selectedPayment}
                    defaultValue={currentItem?.name}
                    onChange={(e) => {
                        handleSelectPayment(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomInput
                    label={`Quantity (${selectedPaymentObject?.unitOfMeasurement})`}
                    value={itemQuantity}
                    onChange={(e) => setItemQuantity(e.target.value)}
                />
                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'column', gap: 5, boxSizing: 'border-box' }}>
                    <label style={{ fontWeight: 600 }}>Amount</label>
                    <div style={{ border: "1.5px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {selectedPaymentObject?.currency} {parseFloat(itemQuantity * selectedPaymentObject?.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                </div>
                <CustomButton loading={buttonLoading} onClick={() => handlePay()}>
                    Submit
                </CustomButton>
            </CustomModal>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                <Title>Transactions</Title>
                <div style={{ width: "150px" }}>
                    <CustomButton onClick={() => setOpenModal(true)}>
                        Make Payment
                    </CustomButton>
                </div>
            </div>
            <ReusableTable
                tableHeader={
                    [
                        { id: 'transactionId', label: 'ID', numeric: false },
                        { id: 'customerName', label: 'Customer', numeric: false },
                        { id: 'customerEmail', label: 'Email', numeric: false },
                        { id: 'itemName', label: 'Item', numeric: false },
                        { id: 'itemQuantity', label: 'Quantity', numeric: false },
                        { id: 'currency', label: 'Currency', numeric: false },
                        { id: 'amount', label: 'Amount', numeric: false },
                        { id: 'createdAt', label: 'Date', numeric: false },
                        { id: 'status', label: 'Status', numeric: false },
                    ]
                }
                tableData={data}
                tableAction={tableAction}
            />
        </>
    );
}