import React, { useState } from "react";

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import { styled } from "@mui/system";

const shippingScheduleData = [
    {
        "berth": "AHT-1 Berth",
        "vesselName": "BELLATRIX",
        "imoNumber": "344575",
        "length": "159.8",
        "berthDate": "2023-08-24",
        "etd": "2023-08-26 15:37:00",
        "rotation": "NO",
        "agent": "APM TERMINALS",
        "cargo": "GENERAL CARGO",
        "port": "Apapa"
    },
    {
        "berth": "AHT-2 Berth",
        "vesselName": "ELARA MOON",
        "imoNumber": "1004797",
        "length": "153.7",
        "berthDate": "2023-08-24",
        "etd": "2023-08-26 15:37:00",
        "rotation": "NO",
        "agent": "APM TERMINALS",
        "cargo": "GENERAL CARGO",
        "port": "Apapa"
    },
    {
        "berth": "AHT-3 Berth",
        "vesselName": "MACAULAY",
        "imoNumber": "242275",
        "length": "120",
        "berthDate": "2023-08-24",
        "etd": "2023-08-26 15:37:00",
        "rotation": "NO",
        "agent": "APM TERMINALS",
        "cargo": "GENERAL CARGO",
        "port": "Tincan"
    },
];

const TabsContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
}))

const TabsList = styled("ul")(() => ({
    display: "flex",
    gap: "2rem",
    borderBottom: "0.1rem solid #ccc",
    listStyle: "none",
}))

const Tab = styled("li")(({ isSelected }) => ({
    padding: "0.5rem",
    margin: "0.5rem",
    borderRadius: "0.25rem",
    borderBottom: `${isSelected ? "0.25rem solid green" : ""}`,
    color: `${isSelected ? "black" : "#6b7280"}`,
    cursor: "pointer",
}));


export default function TrackingPage() {
    const [selectedTab, setSelectedTab] = useState('Apapa');
    const [filteredData, setFilteredData] = useState(shippingScheduleData);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        setFilteredData(shippingScheduleData.filter((item) => item.port === tab));
    };


    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                <Title>Tracking</Title>
            </div>
            <TabsContainer>
                <TabsList>
                    <Tab isSelected={selectedTab === 'Apapa'} onClick={() => handleTabClick('Apapa')}>Apapa</Tab>
                    <Tab isSelected={selectedTab === 'Tincan'} onClick={() => handleTabClick('Tincan')}>Tincan</Tab>
                    <Tab isSelected={selectedTab === 'Lekki'} onClick={() => handleTabClick('Lekki')}>Lekki</Tab>
                    <Tab isSelected={selectedTab === 'Calabar'} onClick={() => handleTabClick('Calabar')}>Lagos Azace</Tab>
                    <Tab isSelected={selectedTab === 'Warri'} onClick={() => handleTabClick('Warri')}>Legas Tincar</Tab>
                    <Tab isSelected={selectedTab === 'Onne'} onClick={() => handleTabClick('Onne')}>Onne</Tab>
                    <Tab isSelected={selectedTab === 'Rivers'} onClick={() => handleTabClick('Rivers')}>Rivers</Tab>
                </TabsList>
            </TabsContainer>
            <ReusableTable
                tableHeader={
                    [
                        { id: 'berth', label: 'Berth', numeric: false },
                        { id: 'vesselName', label: 'Vessel Name', numeric: false },
                        { id: 'imoNumber', label: 'IMO Number', numeric: false },
                        { id: 'length', label: 'Length', numeric: true },
                        { id: 'berthDate', label: 'Berth Date', numeric: false },
                        { id: 'etd', label: 'ETD', numeric: false },
                        { id: 'rotation', label: 'Rotation', numeric: false },
                        { id: 'agent', label: 'Agent', numeric: false },
                        { id: 'cargo', label: 'Cargo', numeric: false },
                        { id: 'port', label: 'Port', numeric: false },
                    ]
                }
                tableData={filteredData}
            // tableAction={tableAction} 
            />
        </>
    );
}