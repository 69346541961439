import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Paper from '@mui/material/Paper';
import CustomInput from "../../components/input/CustomInput";
import FormTitle from "../../components/text/FormTitle";
import Logo from "../../assets/logo.png";
import CustomButton from "../../components/button/CustomButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';

export default function VerifyEmailPage() {
    const navigate = useNavigate();
    const { email, token } = useParams();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('success');

    useEffect(() => {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email": email,
            "token": token
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/auth/verify-email`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 200) {
                    setMessage(result?.message);
                    setMessageType("success");
                } else {
                    setMessage(result?.message);
                    setMessageType("error");
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setMessage("An error occured, try again !");
                setMessageType("error");
                setLoading(false);
            });
    }, [email, token]);

    return (
        <PageWrapper>
            <FormWrapper>
                {!loading && <>
                    <img src={Logo} alt="Logo" width={100} style={{ alignSelf: "center" }} />
                    <FormTitle variant="h5" color="#00A758">Nigerian Ports Authority</FormTitle>
                    <Paper elevation={10} className="form">
                        <FormTitle variant="h6" color={messageType === "error" ? "#ff3333" : "#00A758"}>{message}</FormTitle>
                        <CustomButton
                            fullWidth
                            size="medium"
                            type="submit"
                            variant="contained"
                            onClick={() => navigate("/auth/login")}
                        >Login</CustomButton>
                    </Paper>
                </>}
            </FormWrapper>
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#F5F8F9',
    width: '100%',
    height: '100vh',
    padding: '0rem 0rem',
}));

const FormWrapper = styled('div')(() => ({
    maxWidth: 480, // 480
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: "12 0",
    boxSizing: "border-box",
    "& .form": {
        border: "0px solid red",
        padding: 20,
        margin: 15,
        boxSizing: 'border-box',
        backgroundColor: "#fff",
    },
    "& .linkRow": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 10,
        "& .link": {
            color: "#00701F"
        }
    }
}));