import React, { useState } from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import SchoolIcon from "@mui/icons-material/School";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import QuizIcon from "@mui/icons-material/Quiz";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ChatIcon from "@mui/icons-material/Chat";
import HelpIcon from "@mui/icons-material/Help";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from '@mui/icons-material/Person';
import ForumIcon from '@mui/icons-material/Forum';
import CampaignIcon from '@mui/icons-material/Campaign';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuItem from "./Components/MenuItem";
import SubMenuItem from "./Components/SubMenuItem";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Sailing from '@mui/icons-material/Sailing';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import BarChartIcon from '@mui/icons-material/BarChart';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import LeftArrow from "../../assets/images/left-arrow.svg";
import RightArrow from "../../assets/images/right-arrow.svg";
import MenuIcon from '@mui/icons-material/Menu';

import LogoFull from "../../assets/images/logo-full.png";
import LogoShort from "../../assets/images/logo-short.png";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/actions/menuAction";
import { Settings } from "@mui/icons-material";
import useIsMobile from "../../utils/useIsMobile";

const routeList = [
  {
    label: "Dashboard",
    path: "/",
    icon: <HomeIcon />,
  },
  {
    label: "Transactions",
    path: "/transactions",
    icon: <BarChartIcon />,
  },
  {
    label: "Tariff",
    path: "/tariff",
    icon: <AttachMoneyIcon />,
  },
  {
    label: "Tracking",
    path: "/tracking",
    icon: <LocationOnIcon />,
  },
  {
    label: "Customers",
    path: "/customers",
    icon: <PeopleIcon />,
  },
  {
    label: "Asset",
    path: "/asset",
    icon: <BusinessIcon />,
    isAdmin: true,
  },
  {
    label: "Settings",
    path: "/settings",
    icon: <Settings />,
  },
];

const Sidebar = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const profile = useSelector((store) => store.user.profile);
  // const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [currentMenu, setCurrentItem] = useState("Dashboard");
  const [openedSubmenuItem, setOpenedSubmenuItem] = useState("");
  const sidebarCollapsed = useSelector((store) => store.menu.sidebarCollapsed);
  const dispatch = useDispatch();

  const handleLogout = () => {
    Swal.fire({
      //title: 'Are you sure you want to logout?',
      title: 'Logout',
      text: "Are you sure you want to logout?",
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("type");
        navigate("/auth/login");
      }
    });
  };

  return (
    <SidebarWrapper collapsed={sidebarCollapsed}>
      {/* <SidebarCloseBtn onClick={() => dispatch(toggleSidebar(!sidebarCollapsed))}>
        <MenuIcon color="#ddd" />
      </SidebarCloseBtn> */}
      <SidebarLogoWrapper collapsed={sidebarCollapsed}>
        {!sidebarCollapsed ? (
          <img src={LogoFull} alt="logo" height="65px" />
        ) : (
          <img src={LogoShort} alt="logo" height="65px" />
        )}
      </SidebarLogoWrapper>
      <SidebarMenuList>
        <>
          {routeList.map((route, index) => {
            if(route?.isAdmin && profile?.userType !== "admin") {
              return <></>;
            } else {
              return route.children ? (
                <SubMenuItem
                  Icon={route.icon}
                  label={route.label}
                  collapsed={sidebarCollapsed}
                  onClick={() => {
                    if (openedSubmenuItem === route.label) {
                      setOpenedSubmenuItem("");
                    } else setOpenedSubmenuItem(route.label);
                    if (sidebarCollapsed) {
                      dispatch(toggleSidebar(false));
                    }
                  }}
                  open={openedSubmenuItem === route.label}
                  key={index}
                >
                  {route.children.map((childRoute, i) => (
                    <div style={{ marginLeft: "24px" }} key={i}>
                      <MenuItem
                        Icon={childRoute.icon}
                        label={childRoute.label}
                        selected={currentMenu === childRoute.label}
                        collapsed={sidebarCollapsed}
                        onClick={() => {
                          setCurrentItem(childRoute.label);
                          navigate(childRoute.path);
                        }}
                      />
                    </div>
                  ))}
                </SubMenuItem>
              ) : (
                <MenuItem
                    Icon={route.icon}
                    label={route.label}
                    selected={currentMenu === route.label}
                    collapsed={sidebarCollapsed}
                    onClick={() => {
                      setCurrentItem(route.label);
                      setOpenedSubmenuItem("");
                      navigate(route.path);
                      if(isMobile === true){
                        dispatch(toggleSidebar(true));
                      }
                    }}
                    key={index}
                  />
              );
            }
          })}
          
        </>
        
        <DownMenuList>
          <MenuItem
            Icon={<LogoutIcon />}
            label="Logout"
            //selected={currentMenu === "Logout"}
            collapsed={sidebarCollapsed}
            onClick={() => handleLogout()}
            selected={true}
          />
        </DownMenuList>
      </SidebarMenuList>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled("div")(({ collapsed }) => ({
  height: "100%",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  width: `${collapsed ? "5rem" : "17rem"}`,
  position: "relative",
  display: `${collapsed ? "none" : "flex"}`,
  flexDirection: "column",
  zIndex: 999,
}));

const SidebarLogoWrapper = styled("div")(({ collapsed }) => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  borderBottom: "1px solid #E1E1E1",
  display: `${collapsed ? "flex" : ""}`,
  justifyContent: `${collapsed ? "center" : ""}`,
}));

const SidebarMenuList = styled("div")(() => ({
  marginTop: "24px",
  flex: 1,
  justifycontent: "space-between",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  overflowX: "hidden",
}));

const DownMenuList = styled(Box)`
  border-top: 0.87px solid #ebebeb;
  border: 0px solid red;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0rem 0rem;
  margin: 24px 0 0;
`;

const SidebarCloseBtn = styled(Box)`
  position: absolute;
  right: -40px;
  border: 0px solid #ebebeb;
  top: 22px;
  width: 37px;
  height: 31px;
  border-radius: 0px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
  &:hover {
    background: #ebebeb;
  }
  > img {
    width: 6px;
    height: 11px;
  }
`;

export default Sidebar;
