import React, {useEffect, useState} from "react";
import { styled } from "@mui/system";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts';
import PieChart from "../../components/charts/PieChart";
import PieLabel from "../../components/charts/PieLabel";

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";

export default function DashboardPage() {
    const [data, setData] = useState({
        "statData": [
            {
                "card": {
                    "backgroundColor": "#D0F2FF",
                    "title": "Transactions (Ports)",
                    "total": 900
                },
                "data": {
                    "labels": [
                        "Lagos - Apapa",
                        "Lagos - Tincan",
                        "Calabar Ports",
                        "Warri Ports",
                        "Onne Ports",
                        "Rivers Ports",
                        "Lekki Deep Sea"
                    ],
                    "datasets": [
                        {
                            "data": [
                                200,
                                100,
                                150,
                                200,
                                100,
                                150
                            ],
                            "backgroundColor": [
                                "#FF5733",
                                "#33FF57",
                                "#3357FF",
                                "#FF33A1",
                                "#FFD433",
                                "#33FFF3"
                            ],
                            "borderColor": "transparent",
                            "borderWidth": 0,
                            "dataColors": [
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff"
                            ]
                        }
                    ]
                }
            },
            {
                "card": {
                    "backgroundColor": "#D0F2FF",
                    "title": "Transactions (Status)",
                    "total": 900
                },
                "data": {
                    "labels": [
                        "Success",
                        "Failed"
                    ],
                    "datasets": [
                        {
                            "data": [
                                50,
                                850
                            ],
                            "backgroundColor": [
                                "darkred", 
                                "#00A758"
                            ],
                            "borderColor": "transparent",
                            "borderWidth": 0,
                            "dataColors": [
                                "#fff",
                                "#fff"
                            ]
                        }
                    ]
                }
            }
        ],
    });
    
    const paymentList = [
        {id: 1, customer: "Abdullah Abbas", item: "SHIP DUES - Shifting (All Vessels)", amount: "USD 10,500.23", date: "2024-09-23", status: "Paid"},
        {id: 2, customer: "Michael John", item: "BERTH RENT - Container And Roro Vessels", amount: "USD 7,020.00", date: "2024-09-23", status: "Paid"},
        {id: 3, customer: "Abdullah Abbas", item: "BERTH RENT - Liquid Bulk Cargo Vessels", amount: "USD 230,500.23", date: "2024-09-23", status: "Paid"},
        {id: 4, customer: "Michael John", item: "SHIP DUES - Second Port Of Call On Same Voyage", amount: "USD 457,020.00", date: "2024-09-23", status: "Paid"},
        {id: 5, customer: "Abdullah Abbas", item: "THIRD PARTY TOWAGE - 200 LOA & Above", amount: "USD 50,500.00", date: "2024-09-23", status: "Paid"},
    ]

    return(
        <>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20}}>
                <Title>Dashboard</Title>
                <div style={{width: "150px"}}></div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20, height: "auto"}}>
                <Grid container spacing={2}>
                    {
                        data.statData.map((item, index) => (
                            <Grid key={index} item xs={12} md={6}>
                                <CardWrapper color={item.card.backgroundColor}>
                                    <div className="div">
                                        <CardTitle>{item.card.title}</CardTitle>
                                        <CardValue>{item.card.total}</CardValue>
                                    </div>
                                    <div className="div">
                                        <div>
                                            <PieChart data={item.data} />
                                        </div>
                                        <div>
                                            <PieLabel data={item.data} />
                                        </div>
                                    </div>
                                </CardWrapper>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20}}>
                <Title>Recent Transactions</Title>
            </div>
            <ReusableTable 
                tableHeader={
                    [
                        { id: 'id', label: 'ID', numeric: false },
                        { id: 'customer', label: 'Customer', numeric: false },
                        { id: 'item', label: 'Item', numeric: false },
                        { id: 'amount', label: 'Amount', numeric: false },
                        { id: 'date', label: 'Date', numeric: false },
                        { id: 'status', label: 'Status', numeric: false },
                    ]
                }
                tableData={paymentList}
            />
        </>
    );
}

const CardWrapper = styled('div')(({color}) => ({
    backgroundColor: color,
    width: '100%',
    height: '240px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: '8px',
    padding: "20px",
    boxSizing: "border-box",
    gap: 20,
    "& .div": {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "space-between",
    }
}));

const CardTitle = styled('div')(() => ({
    color: "#252421",
    fontSize: "22px",
    fontWeight: 600,
}));

const CardValue = styled('h4')(() => ({
    color: "#000",
    fontSize: "48px",
    fontWeight: 600,
}));