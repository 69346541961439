import React from "react";
import Title from "../../components/text/Title";

export default function SettingsPage() {

    return(
        <>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20}}>
                <Title>Settings</Title>
                <div style={{width: "150px"}}></div>
            </div>
        </>
    );
}