import React, { useState } from "react";
import { styled } from "@mui/system";
import Paper from '@mui/material/Paper';
import CustomInput from "../../components/input/CustomInput";
import FormTitle from "../../components/text/FormTitle";
import Logo from "../../assets/logo.png";
import CustomButton from "../../components/button/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';

export default function LoginPage() {
    const navigate = useNavigate();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [buttonLoading, setButtonLoading] = useState('');

    const handleClick = () => {
        if(!email || !password){
            setAlertMessage("Email and password are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                email: email,
                password: password
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            
            fetch(`${window.backendUrl}/api/v1/auth/login`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        localStorage.setItem("userToken", result?.data?.token);
                        navigate("/");
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    return (
        <PageWrapper>
            <MessageAlert 
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <FormWrapper>
                <img src={Logo} alt="Logo" width={100} style={{alignSelf: "center"}} />
                <FormTitle variant="h5" color="#00A758">Nigerian Ports Authority</FormTitle>
                <Paper elevation={10} className="form">
                    <FormTitle variant="h6">Login</FormTitle>
                    <CustomInput
                        size="medium"
                        variant="outlined"
                        name="email"
                        type="email"
                        label="Email Address"
                        placeholder="Email Address"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <CustomInput
                        size="medium"
                        variant="outlined"
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <CustomButton 
                        fullWidth
                        size="medium"
                        type="submit"
                        variant="contained"
                        onClick={() => handleClick()}
                        loading={buttonLoading}
                        disabled={buttonLoading}
                    >Login</CustomButton>
                    <div className="linkRow">
                        <Link className="link" to={"/auth/forgot-password"} >Forgot Password</Link>
                        <Link className="link" to={"/auth/register"} >Register</Link>
                    </div>
                </Paper>
            </FormWrapper>
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#F5F8F9',
    width: '100%',
    height: '100vh',
    padding: '0rem 0rem',
}));

const FormWrapper = styled('div')(() => ({
    maxWidth: 480, // 480
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: "12 0",
    boxSizing: "border-box",
    "& .form": {
        border: "0px solid red",
        padding: 20,
        margin: 15,
        boxSizing: 'border-box',
        backgroundColor: "#fff",
    },
    "& .linkRow": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 10,
        "& .link": {
            color: "#00701F"
        }
    }
}));